import React, { useContext, useEffect, useState } from 'react';
import { FaqSearchContext } from '../../contexts/faqSearchContext';
import { SimpleFilterContext } from '../../contexts/simpleFilterContext';
import { AccordionList, AccordionStyle } from './Accordion.styles';
import AccordionCard from './AccordionCard/AccordionCard';

const Accordion = ({ accordions }) => {
  const [isOpen, setIsOpen] = useState(null);
  const { simpleFilterState } = useContext(SimpleFilterContext);
  const { faqSearchState, faqSearchDispatch } = useContext(FaqSearchContext);
  let count = 0;

  const handleClick = id => {
    setIsOpen(isOpen === id ? null : id);
  };

  useEffect(() => {
    faqSearchDispatch({ type: 'SET_COUNT', payload: count });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqSearchState.search]);

  return (
    <AccordionStyle>
      {accordions &&
        accordions.map(accordion => {
          const { heading, accordionItems, id } = accordion;
          if (
            simpleFilterState?.filter &&
            simpleFilterState.filter !== 'all' &&
            simpleFilterState.filter !== id
          ) {
            return;
          }

          return (
            <AccordionList key={id}>
              {accordions.length > 1 && heading && !faqSearchState.search && (
                <h3>{heading}</h3>
              )}
              {accordionItems.map(item => {
                if (
                  faqSearchState.search &&
                  !item.heading
                    .toLowerCase()
                    .includes(faqSearchState.search.toLowerCase())
                ) {
                  return;
                }
                count += 1;

                return (
                  <AccordionCard
                    key={item.id}
                    heading={item.heading}
                    headingTag={item.headingTag}
                    subhead={item.subhead}
                    list={item?.list}
                    open={isOpen === item.id}
                    onClick={() => handleClick(item.id)}
                  />
                );
              })}
            </AccordionList>
          );
        })}
    </AccordionStyle>
  );
};

export default Accordion;
