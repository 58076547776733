import { AnimatePresence } from 'framer-motion';
import React from 'react';
import ListItem from '../../../components/ListItems';
import Icon from '../../../styles/atoms/icons';
import { getTag } from '../../../utils/getTag';
import {
  AccordionBody,
  AccordionCardStyle,
  AccordionHeader,
} from './AccordionCard.styles';

const AccordionCard = ({
  heading,
  headingTag,
  list,
  open,
  subhead,
  ...props
}) => {
  const HeadingTag = getTag(headingTag || 'Heading 4');

  return (
    <AccordionCardStyle open={open} {...props}>
      <AccordionHeader open={open}>
        <HeadingTag>{heading && heading}</HeadingTag>
        {open ? <Icon id="FiMinus" /> : <Icon id="FiPlus" />}
      </AccordionHeader>
      <AnimatePresence>
        {open && (
          <AccordionBody
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: { duration: 0.3 },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: subhead }} />
            {list && <ListItem items={list} />}
          </AccordionBody>
        )}
      </AnimatePresence>
    </AccordionCardStyle>
  );
};

export default AccordionCard;
